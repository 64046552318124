




























































































import { Component, Vue } from "vue-property-decorator";
import AppAddressWidget from "@/components/widgets/AppAddressWidget.vue";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppLineItem from "@/components/AppLineItem.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppOrderInstructionsWidget from "@/components/widgets/AppOrderInstructionsWidget.vue";
import AppOrderMessageWidget from "@/components/widgets/AppOrderMessageWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import cartHandler from "@/store/cart";
import { createOrder } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";
import AppCCWidget from "@/components/widgets/AppCCWidget.vue";

@Component({
  components: {
    AppCCWidget,
    AppAddressWidget,
    AppAlert,
    AppButton,
    AppLineItem,
    AppListGroup,
    AppListGroupItem,
    AppOrderInstructionsWidget,
    AppOrderMessageWidget,
    TheHeader,
    TheMain,
  },
})
export default class Checkout extends Vue {
  notification: Notification | null = null;
  order: pro.OrderDetail | null = null;
  saving = false;

  mounted() {
    this.order = cartHandler.cart ?? null;
  }

  async createOrder(): Promise<void> {
    this.notification = null;
    this.saving = true;

    try {
      if (!this.order) throw new Error("Unable to submit sample request.");
      this.order = await createOrder(this.order);
      this.clearCart();
      this.redirectToOrder();
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to submit sample request.",
        err.message
      );
    }

    this.saving = false;
  }

  goBack(): void {
    this.$router.push({ name: "ORDER_CART" });
  }

  clearCart() {
    cartHandler.clear();
  }

  redirectToOrder() {
    this.$router.push({
      name: "ORDER",
      params: {
        catalogId: String(this.order?.catalog?.id),
        orderId: String(this.order?.id),
      },
    });
  }
}
